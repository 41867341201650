<template>
  <MainScreen/>
  <RevolutionScreen/>
  <KeyFeatures/>
  <Tokenomics/>
  <Roadmap/>
  <Footer/>
</template>

<script>
import MainScreen from './components/MainScreen.vue'
import RevolutionScreen from './components/RevolutionScreen.vue'
import KeyFeatures from './components/KeyFeatures.vue'
import Tokenomics from './components/Tokenomics.vue'
import Roadmap from './components/Roadmap.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    MainScreen,
    RevolutionScreen,
    KeyFeatures,
    Tokenomics,
    Roadmap,
    Footer
  }
}
</script>

<style>

*,
::before,
::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: 'BaiJamjuree';
  src: url(./assets/fonts/BaiJamjuree-Medium.ttf);
}

@font-face {
  font-family: 'BaiJamjuree Bold';
  src: url(./assets/fonts/BaiJamjuree-SemiBold.ttf);
}

html {
  font-size: 16px;
}

body {
  background: #0A0A0A;
}

#app {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  color: #FFFFFF;
  font-family: 'BaiJamjuree';
}

.container {
  width: 100%;
  padding: 0 7%;
}

h1 {
  font-size: 3.4375rem;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.02em;
}

h2 {
  font-size: 2.5rem;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.02em;
}

h3 {
  font-size: 1.375rem;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.02em;
}

h4 {
  font-family: 'BaiJamjuree Bold';
  font-size: 1.375rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #1578D3;
}

.maintext {
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ABABAB;
}

.maintext2 {
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ABABAB;
}

.subtext {
  font-size: 1.125rem;
  line-height: 140%;
  letter-spacing: -0.02em;
}

.mainbutton {
  font-family: 'BaiJamjuree Bold';
  font-size: 1.25rem;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #FFFFFF;

  padding: .9375rem 1.875rem;
  background: linear-gradient(108.74deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.06) 100%);
  box-shadow: 0px 0px 50px -25px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
  border-radius: .9375rem;

  width: fit-content;
  cursor: pointer;

  position: relative;

  transition: all .2s;
}

/* .mainbutton::after {
  content: '';
  position: absolute;
  top: -3px;
  bottom: -3px;
  left: -3px;
  right: -3px;
  background: linear-gradient(to bottom right, #22c1c3, #fdbb2d);
  z-index: -1;
  border-radius: .9375rem;
} */

.mainbutton::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: .9375rem;
  padding: 3px; 
  /* background:linear-gradient(45deg,red,blue);  */
  background: linear-gradient(108.74deg, rgba(105, 120, 255, 0.282) 0%, rgba(255, 255, 255, 0.54) 36.46%, rgba(255, 255, 255, 0.3) 73.96%, rgba(13, 220, 170, 0.246) 100%),
linear-gradient(108.74deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.06) 100%);
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
}

.mainbutton:hover, 
.socialbutton:hover
 {
  opacity: .8;
  color: #1578D3;
}

.socialbutton {
  height: 3.75rem;
  width: 3.75rem;

  position: relative;

  background: linear-gradient(108.74deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.06) 100%);
  box-shadow: 0px 0px 50px -25px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
  border-radius: .3125rem;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  transition: all .2s;
}

.socialbutton path {
  transition: all .2s;
}

.socialbutton:hover path {
  fill: #FFFFFF;
}

.socialbutton::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: .3125rem;
  padding: 2px; 
  /* background:linear-gradient(45deg,red,blue);  */
  background: linear-gradient(108.74deg, rgba(105, 120, 255, 0.282) 0%, rgba(255, 255, 255, 0.54) 36.46%, rgba(255, 255, 255, 0.3) 73.96%, rgba(13, 220, 170, 0.246) 100%),
linear-gradient(108.74deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.06) 100%);
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
}




.subblock {
  background: linear-gradient(108.74deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.06) 100%);
  box-shadow: 0px 0px 50px -25px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
  border-radius: .9375rem;
  position: relative;
}

.subblock::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: .9375rem;
  padding: 3px; 
  background: linear-gradient(108.74deg, rgba(105, 120, 255, 0.282) 0%, rgba(255, 255, 255, 0.54) 36.46%, rgba(255, 255, 255, 0.3) 73.96%, rgba(13, 220, 170, 0.246) 100%),
linear-gradient(108.74deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.06) 100%);
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
}





@media screen and (max-width: 1100px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) {
  .socialbutton {
    height: 2.75rem;
    width: 2.75rem;
  }

  .socialbutton svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.logo-text {
    font-size: 28px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5)
}
.logo-text .ai {
    color: #10beff;
}

</style>
