<template>
    <div>
        <div class="container">
            <h2>Roadmap</h2>
            <div class="roadmap">
                <div class="column">
                    <div class="subblock item item0">
                        <h4>Phase 1</h4>
                        <div class="subtext">
                            — Stealth launch<br>
                            — Website V1<br>
                            — Twitter/Telegram marketing campaign<br>
                            — Marketing from Buy Bot Tech<br>
                            — 250+ holders
                        </div>
                    </div>
                    <div class="subblock item item1">
                        <h4>Phase 3</h4>
                        <div class="subtext">
                            — CoinGecko Listing<br>
                            — CoinMarketCap Listing<br>
                            — Partnerships<br>
                            — Dextools trending<br>
                            — MC 1 000 000$+
                        </div>
                    </div>
                </div>

                <div class="horizlinecol">
                    <div class="horizline item0"></div>
                    <div class="horizline item1"></div>
                </div>

                <div class="line"></div>

                <div class="horizlinecol">
                    <div class="horizline item2"></div>
                    <div class="horizline item3"></div>
                </div>

                <div class="column">
                    <div class="subblock item item2">
                        <h4>Phase 2</h4>
                        <div class="subtext">
                            — Dextools social update<br>
                            — ETH Trending<br>
                            — Competitions<br>
                            — NEW Twitter/Telegram marketing campaign<br>
                            — 750+ holders
                        </div>
                    </div>
                    <div class="subblock item item3">
                        <h4>Phase 4</h4>
                        <div class="subtext">
                            — MVP release<br>
                            — Trending CG (UK&USA)<br>
                            — Partnership with <a href="https://www.certik.com/" target="_block">Certik</a><br>
                            — MC 10 000 000$+
                        </div>
                    </div>
                </div>

                <div class="subblock dot item0"></div>
                <div class="subblock dot item1"></div>
                <div class="subblock dot item2"></div>
                <div class="subblock dot item3"></div>
            </div>
            <div class="mainbutton" v-on:click="openLink('https://app.uniswap.org/#/swap?inputCurrency=eth&outputCurrency=0xD552163A9F17645CC64348ad436bC0390B7a5555', true)">BUY $DAI</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RoadMap',

    methods: {
        openLink(url, newtab) {
            if (newtab) {
                window.open(url,'_blank')
            } else {
                window.open(url, '_self')
            }
        }
    }
}
</script>

<style scoped>

.container {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
}

.mainbutton {
    margin: 0 auto;
}

.roadmap {
    margin: 5rem 0;
    display: flex;
    justify-content: space-between;

    position: relative;
}

.line {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #0F919E 51.04%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 50px rgba(0, 0, 0, 0.5);
    width: 3px;
    height: 63rem;
}

.column {
    width: 40%;
    position: relative;
}

.subblock.item {
    padding: 2.5rem;
    width: 100%;
    /* position: absolute; */
    height: 15.75rem;

    background: linear-gradient(108.74deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.06) 100%);
}

.subblock h4 {
    position: relative;
    z-index: 1;
}

.subtext {
    position: relative;
    z-index: 1;
    margin-top: 1.25rem;
}

.subtext a {
    color: #1578D3;
    text-decoration: none;
    transition: opacity .2s;
}

.subtext a:hover {
    opacity: .75;
}

.subblock.item1 {
    top: 25%;
}

.subblock.item2 {
    top: 25%;
}

.subblock.item3 {
    top: 50%;
}

.horizlinecol {
    width: calc(10% - 1.5px);
    position: relative;
}

.horizline {
    position: absolute;
    width: 100%;
    height: 3px;
    background: #353B3C;
}

.dot {
    position: absolute;
    padding: 05px;
    left: 50%;
    height: 0;
    width: 0;
}

.horizline.item0 {
    top: 7.875rem;
}
.horizline.item1 {
    top: calc(50% + 7.875rem);
}
.horizline.item2 {
    bottom: 7.875rem;
}
.horizline.item3 {
    bottom: calc(50% + 7.875rem);
}

.dot.item0 {
    top: 7.875rem;
    transform: translate(-50%, -30%);
}
.dot.item1 {
    top: calc(50% + 7.875rem);
    transform: translate(-50%, -30%);
}
.dot.item2 {
    bottom: 7.875rem;
    top: auto;
    transform: translate(-50%, 30%);
}
.dot.item3 {  
    top: auto;  
    bottom: calc(50% + 7.875rem);
    transform: translate(-50%, 30%);
}


@media screen and (max-width: 900px) {
    .subblock.item {
        padding: 1.5rem;
    }

    .subtext {
        margin-top: .75rem;
    }
}

@media screen and (max-width: 650px) {
    .roadmap {
        flex-direction: column;
        align-items: center;
        gap: 4rem;
    }

    .dot, .horizlinecol {
        display: none;
    }

    .subblock.item {
        position: relative;
        max-width: 25rem;
        margin: 0 auto;
    }

    .line {
        position: absolute;
        top: 0;
        height: 100%;
        z-index: -1;
        left: 50%;
        transform: translateX(-50%);
    }

    .column {
        width: 100%;
    }

    .subblock.item1,
    .subblock.item3 {
        margin-top: 4rem;
    }
}

</style>