<template>
    <div class="container">
        <h2>Key Features Dexos AI</h2>
        <div class="mainblock">
            <img class="mainblockimg" src="../assets/3.png">

            <div class="mainblockitem item0">
                <img src="../assets/10.png">
                <h3>Exclusive Access</h3>
                <div class="maintext2">Join an elite group of investors by holding 10,000,000 DAI tokens and unlock the full potential of Dexos AI, revolutionizing your DeFi trading experience.</div>
            </div>

            <div class="mainblockitem item1">
                <img src="../assets/11.png">
                <h3>Anti Rug Pull</h3>
                <div class="maintext2">Dexos AI detects if a token creator intends to remove liquidity maliciously and preemptively sells all tokens before the scammer's transaction is executed, protecting users from potential losses.</div>
            </div>

            <div class="mainblockitem item2">
                <img src="../assets/12.png">
                <h3>Enhanced Security Measures</h3>
                <div class="maintext2">Before executing a trade, the bot conducts thorough security tests on tokens to ensure that the investment is safe and the project is legitimate.</div>
            </div>

            <div class="mainblockitem item3">
                <img src="../assets/13.png">
                <h3>Reduced Gas Fees</h3>
                <div class="maintext2">Boost your trading efficiency with up to 50% off on gas fees, increasing your overall profitability in the competitive decentrali</div>
            </div>

            <div class="mainblockitem item4">
                <img src="../assets/14.png">
                <h3>Customizable Strategies</h3>
                <div class="maintext2">Tailor your approach with flexible buy/sell strategies, creating a personalized investment plan that aligns with your financial goals.</div>
            </div>

            <div class="mainblockitem item5">
                <img src="../assets/15.png">
                <h3>Intelligent Automated Trading</h3>
                <div class="maintext2">Dexos AI can automatically identify and purchase promising tokens with high growth potential before they become widely popular across ETH, BSC, Polygon, and Arbitrum networks.</div>
            </div>

            
        </div>
    </div>
</template>

<script>

export default {
    name: 'KeyFeatures'
}
</script>

<style scoped>

.mainblock {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5rem 0;
    height: 50rem;
}

.mainblockimg {
    height: 21.5rem;
}

.mainblockitem {
    position: absolute;
    max-width: 20.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mainblockitem img {
    height: 3.75rem;
}

.mainblockitem h3 {
    margin: 1.875rem 0 1.15rem 0;
}

.mainblockitem.item0 {
    top: 0;
}

.mainblockitem.item1 {
    top: 9.25rem;
    right: 0;
}

.mainblockitem.item2 {
    bottom: 9.25rem;
    right: 0;
}

.mainblockitem.item3 {
    bottom: 0;
}

.mainblockitem.item4 {
    bottom: 9.25rem;
    left: 0;

}

.mainblockitem.item5 {
    top: 9.25rem;
    left: 0;

}


@media screen and (max-width: 1000px) {
    .mainblockimg {
        height: 16.5rem;
    }
}

@media screen and (max-width: 900px) {
    .mainblockimg {
        height: 12.5rem;
    }
}

@media screen and (max-width: 800px) {
    .mainblockimg {
        display: none;
    }

    .mainblockitem {
        position: static;
    }

    .mainblock {
        flex-direction: column;
        height: auto;
        gap: 4rem;
    }
}

@media screen and (max-width: 500px) {
    h2 {
        max-width: 20rem;
        margin: 0 auto;
    }
}


</style>