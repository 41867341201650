<template>
    <div class="container">
        <h2>Tokenomics</h2>
        <div class="maintext main">The Dexos AI (DAI) token is the key to accessing the platform's full potential and enjoying its numerous benefits. Designed with a total supply of 1,000,000,000 tokens, the DAI token has a unique tokenomics structure that supports the project's growth and sustainability:</div>
        <h3>Contract Address</h3>
        <div class="maintext sub">0xD552163A9F17645CC64348ad436bC0390B7a5555</div>
        <div class="blocks">
            <div class="subblock">
                <svg width="60" height="68" viewBox="0 0 60 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.96681 20.9818V31.463M9.96681 20.9818V10.5006C9.96681 8.18517 11.7502 6.30812 13.9502 6.30812C16.1502 6.30812 17.9336 8.18517 17.9336 10.5006M9.96681 20.9818C9.96681 18.6664 8.18336 16.7893 5.9834 16.7893C3.78344 16.7893 2 18.6664 2 20.9818V44.1715C2 55.7488 10.9171 65.1339 21.917 65.1339C32.9169 65.1339 41.834 55.7488 41.834 44.1715V26.2224H38.8465C36.0964 26.2224 33.8672 28.5688 33.8672 31.463V10.5006C33.8672 8.18517 32.0838 6.30812 29.8838 6.30812C27.6839 6.30812 25.9004 8.18517 25.9004 10.5006M17.9336 10.5006V26.2224M17.9336 10.5006V6.1771C17.9336 3.86167 19.7171 1.98462 21.917 1.98462C24.117 1.98462 25.9004 3.86167 25.9004 6.1771V10.5006M25.9004 10.5006V26.2224" stroke="#1578D3" stroke-width="3.73444" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M48.8049 22.03C53.7549 22.03 57.7676 17.8067 57.7676 12.5969C57.7676 7.38716 53.7549 3.16382 48.8049 3.16382C43.855 3.16382 39.8423 7.38716 39.8423 12.5969C39.8423 17.8067 43.855 22.03 48.8049 22.03Z" stroke="#1578D3" stroke-width="3.73444" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M46.8133 14.693L50.7967 10.5005M50.7967 14.693L46.8133 10.5005M33.8672 31.4629V38.7998C33.8672 38.7998 26.8962 38.7998 25.9004 46.1366" stroke="#1578D3" stroke-width="3.73444" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <h3>Limited Access:</h3>
                <div class="maintext2">
                    With only 1,000 users allowed to access the platform, each DAI token becomes increasingly valuable as demand rises. Secure your place in the Dexos AI ecosystem by holding 10,000,000 DAI tokens.
                </div>
            </div>
            <div class="subblock">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.5052 16.0808C3.5052 11.8063 6.98285 8.32863 11.2574 8.32863H12.9875L10.6426 10.6736L13.1211 13.1521L19.6971 6.57609L13.1211 0L10.6426 2.47852L12.9875 4.82355H11.2574C5.05008 4.82344 0 9.87352 0 16.0808V21.0096H3.5052V16.0808ZM56.4948 43.9192C56.4948 48.1938 53.0172 51.6715 48.7425 51.6715H47.0123L49.3573 49.3264L46.8787 46.8479L40.3028 53.424L46.8787 60L49.3573 57.5215L47.0123 55.1766H48.7425C54.9499 55.1766 60 50.1265 60 43.9191V38.9904H56.4948V43.9192ZM44.6556 15.2351C44.6556 14.2439 43.8492 13.4375 42.858 13.4375H38.3861V17.0326H42.858C43.8492 17.0326 44.6556 16.2262 44.6556 15.2351ZM44.6556 22.3458C44.6556 21.3546 43.8492 20.5482 42.858 20.5482H38.3861V24.1433H42.858C43.8492 24.1432 44.6556 23.337 44.6556 22.3458Z" fill="#1578D3"/>
                    <path d="M38.692 35.4529C39.3522 35.5315 40.0197 35.5717 40.6874 35.5717C49.8779 35.5717 57.3549 28.0946 57.3549 18.9042C57.3549 9.71372 49.8778 2.23657 40.6872 2.23657C31.4967 2.23657 24.0198 9.7136 24.0198 18.904C24.0198 19.7818 24.0896 20.6569 24.225 21.5183C31.1692 23.2614 36.6967 28.6114 38.692 35.4529ZM34.8704 9.92185H38.7882V6.36087H42.3038V9.92185H42.858C45.7877 9.92185 48.1712 12.3053 48.1712 15.235C48.1712 16.6011 47.6526 17.8481 46.8025 18.7904C47.6527 19.7327 48.1712 20.9797 48.1712 22.3457C48.1712 25.2754 45.7877 27.6589 42.858 27.6589H42.3038V31.192H38.7882V27.6589H34.8704V9.92185Z" fill="#1578D3"/>
                    <path d="M35.9803 41.0958C35.9803 31.9053 28.5033 24.4282 19.3128 24.4282C10.1224 24.4282 2.64526 31.9054 2.64526 41.0958C2.64526 50.2862 10.1224 57.7633 19.3128 57.7633C28.5033 57.7633 35.9803 50.2862 35.9803 41.0958ZM25.7952 37.3933H22.2796V37.2748C22.2796 36.2219 21.4229 35.3652 20.3701 35.3652H18.2555C17.2026 35.3652 16.346 36.2219 16.346 37.2748V37.3266C16.346 38.3794 17.2026 39.236 18.2555 39.236H20.3701C23.3614 39.236 25.7952 41.6698 25.7952 44.6612C25.7952 47.4151 23.7321 49.6947 21.0707 50.0396V53.3835H17.555V50.0396C14.8936 49.6947 12.8305 47.4151 12.8305 44.6612V44.506H16.3461V44.6612C16.3461 45.714 17.2028 46.5707 18.2556 46.5707H20.3702C21.423 46.5707 22.2797 45.714 22.2797 44.6612C22.2797 43.6083 21.423 42.7517 20.3702 42.7517H18.2556C15.2642 42.7517 12.8305 40.3179 12.8305 37.3266V37.2748C12.8305 34.5208 14.8936 32.2412 17.555 31.8963V28.5524H21.0707V31.8963C23.7321 32.2412 25.7952 34.5208 25.7952 37.2748V37.3933Z" fill="#1578D3"/>
                </svg>
                <h3>Trade Fees (1/1):</h3>
                <div class="maintext2">

                    The 1% tax fee from each buy and sell trade on the DEX is directed to a designated tax wallet. Funds accumulated in this tax wallet are strategically allocated for ongoing development and marketing efforts, ensuring continuous improvement and enhanced visibility of the DEX platform.
                </div>
            </div>
        </div>

        <div class="overlaytop"></div>
        <div class="overlaybottom"></div>
        <div class="overlayleft"></div>
        <div class="overlayright"></div>
    </div>
</template>

<script>
export default {
    name: 'TokenomicsScreen'
}
</script>

<style scoped>

.container {
    position: relative;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../assets/4.png');
    background-size: cover;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

h2 {
    position: relative;
    z-index: 1;
}

.maintext.main {
    max-width: 41.6rem;
    margin: 2.5rem auto;
    font-size: 1.5rem;
}

.maintext.sub {
    margin: 1.25rem 0 5rem;
}

.blocks {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}

.subblock {
    width: 48.25%;
    padding: 3.75rem
}

.subblock h3 {
    text-align: start;
    margin: 1.8rem 0 1.25rem 0;
}

.subblock .maintext2 {
    text-align: start;
    color: #D0D0D0;
}

.overlaytop {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #0A0A0A 73.44%);
    width: 100%;
    height: 9.3rem;
}

.overlaybottom {
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #0A0A0A 73.44%);
    width: 100%;
    height: 9.3rem;
}

.overlayleft {
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, #0A0A0A 73.44%);
    width: 3rem;
    height: 100%;
}

.overlayright {
    position: absolute;
    right: 0;
    top: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #0A0A0A 73.44%);
    width: 3rem;
    height: 100%;
}



@media screen and (max-width: 850px) {
    .subblock {
        padding: 2rem;
    }

    .overlayright,
    .overlayleft {
        display: none;
    }
}

@media screen and (max-width: 650px) {
    .blocks {
        flex-direction: column;
        gap: 1rem;
    }

    .subblock {
        width: auto;
    }

    .maintext.sub {
        word-wrap: break-word;
    }
}

</style>