<template>
    <div class="container">
        <h2>Next Generation of DeFi Trading with Dexos AI</h2>
        <div class="maintext">Tailored to refine your trading tactics and boost gains across diverse networks, Dexos AI integrates superior AI algorithms, adaptable strategies, and robust security protocols. Join an elite private circle and navigate the DeFi landscape with unmatched proficiency. Embrace the Dexos AI ecosystem and witness a transformative shift in decentralized finance trading.</div>
        <div class="mainbutton" v-on:click="openLink('https://t.me/DexosAI_bot', true)">Start Bot</div>
        <div class="line"></div>
    </div>
</template>

<script>
export default {
    name: 'RevolutionScreen',

    methods: {
        openLink(url, newtab) {
            if (newtab) {
                window.open(url,'_blank')
            } else {
                window.open(url, '_self')
            }
        }
    }
}
</script>

<style scoped>

.maintext {
    font-size: 1.25rem;
    margin: 2.5rem auto;
    max-width: 900px;
}

.mainbutton {
    margin: 0 auto;
}

.line {
    background: #2B2B2B;
    height: 1px;
    margin: 6.25rem 0;
}

</style>