<template>
    <div class="subblock">
        <div class="container">
            <span class="logo-text">Dexos <span class="ai">AI</span></span>
            <div class="social">
                <div class="socialbutton" v-on:click="openLink('https://t.me/DexosAi', true)">
                    <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.80956 13.8135L9.39602 19.6302C9.98769 19.6302 10.2439 19.376 10.5512 19.0708L13.3252 16.4198L19.0731 20.6292C20.1273 21.2167 20.87 20.9073 21.1544 19.6594L24.9273 1.98021L24.9283 1.97917C25.2627 0.420839 24.3648 -0.188536 23.3377 0.193755L1.1606 8.68438C-0.35294 9.27188 -0.330023 10.1156 0.90331 10.4979L6.5731 12.2615L19.7429 4.02084C20.3627 3.61042 20.9262 3.83751 20.4627 4.24792L9.80956 13.8135Z" fill="#1578D3"/>
                    </svg>
                </div>
                <div class="socialbutton" v-on:click="openLink('https://twitter.com/Dexos_AI', true)">
                    <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25 2.74844C24.0703 3.15625 23.0797 3.42656 22.0469 3.55781C23.1094 2.92344 23.9203 1.92656 24.3016 0.725C23.3109 1.31562 22.2172 1.73281 21.0516 1.96563C20.1109 0.964063 18.7703 0.34375 17.3078 0.34375C14.4703 0.34375 12.1859 2.64688 12.1859 5.47031C12.1859 5.87656 12.2203 6.26719 12.3047 6.63906C8.04375 6.43125 4.27344 4.38906 1.74062 1.27813C1.29844 2.04531 1.03906 2.92344 1.03906 3.86875C1.03906 5.64375 1.95312 7.21719 3.31562 8.12812C2.49219 8.1125 1.68437 7.87344 1 7.49688V7.55312C1 10.0437 2.77656 12.1125 5.10625 12.5891C4.68906 12.7031 4.23438 12.7578 3.7625 12.7578C3.43438 12.7578 3.10312 12.7391 2.79219 12.6703C3.45625 14.7 5.34063 16.1922 7.58125 16.2406C5.8375 17.6047 3.62344 18.4266 1.22656 18.4266C0.80625 18.4266 0.403125 18.4078 0 18.3563C2.27031 19.8203 4.96094 20.6562 7.8625 20.6562C17.2937 20.6562 22.45 12.8438 22.45 6.07187C22.45 5.84531 22.4422 5.62656 22.4312 5.40938C23.4484 4.6875 24.3031 3.78594 25 2.74844Z" fill="#1578D3"/>
                    </svg>
                </div>
                <a class="socialbutton" v-on:click="openLink('mailto:hello@dexos.pro', false)">
                    <svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.1992 10.5377L14.1054 12.6385C13.2563 13.4906 11.7624 13.5089 10.895 12.6385L8.80107 10.5377L1.28076 18.0824C1.56069 18.2118 1.86934 18.2891 2.19746 18.2891H22.8029C23.1311 18.2891 23.4396 18.2119 23.7194 18.0824L16.1992 10.5377Z" fill="#1578D3"/>
                        <path d="M22.8027 0.710938H2.19727C1.86914 0.710938 1.5605 0.788184 1.28066 0.917627L9.31665 8.98027C9.31719 8.98081 9.31782 8.98091 9.31836 8.98145C9.31869 8.9818 9.31891 8.98224 9.31899 8.98272L11.9319 11.6043C12.2095 11.8818 12.7906 11.8818 13.0682 11.6043L15.6806 8.98315C15.6806 8.98315 15.6812 8.98198 15.6817 8.98145C15.6817 8.98145 15.6829 8.98081 15.6834 8.98027L23.7192 0.917578C23.4394 0.788086 23.1309 0.710938 22.8027 0.710938ZM0.233691 1.94199C0.0888672 2.23486 0 2.55996 0 2.9082V16.0918C0 16.44 0.0887695 16.7651 0.233643 17.058L7.76699 9.50024L0.233691 1.94199ZM24.7663 1.94189L17.2331 9.50024L24.7663 17.0581C24.9111 16.7652 25 16.4401 25 16.0918V2.9082C25 2.55986 24.9111 2.23477 24.7663 1.94189Z" fill="#1578D3"/>
                    </svg>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterScreen',

    methods: {
        openLink(url, newtab) {
            if (newtab) {
                window.open(url,'_blank')
            } else {
                window.open(url, '_self')
            }
        }
    }
}
</script>

<style scoped>

.subblock,
.subblock::before {
    border-radius: 3rem 3rem 0 0;
}

.subblock {
    background: linear-gradient(108.74deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.06) 100%);
    box-shadow: 0px 0px 50px -25px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(50px);
}

.container {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social {
    display: flex;
    gap: 2rem;
    align-items: center;
}


@media screen and (max-width: 500px) {
    .social {
        gap: 1rem;
        margin-left: 1.5rem;
    }
}

</style>