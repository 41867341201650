<template>
    <div class="container">
        <div class="nav">
            <span class="logo-text">Dexos <span class="ai">AI</span></span>
            <div class="mainbutton" v-on:click="openLink('https://t.me/DexosAI_bot', true)">Start Bot</div>
        </div>
        <div class="mainscreen">
            <div>
                <h1 class="text text-block">Enhance Your DEX Experience with Cutting-Edge AI Technology</h1>
                <div class="buttons">
                    <div class="mainbutton" v-on:click="openLink('', true)">BUY $DAI</div>
<!--                    <div class="mainbutton" v-on:click="openLink('../WhitePaper.pdf', true)">White Paper</div>-->
                </div>
            </div>
        </div>

        <div class="overlaybottom"></div>
        <div class="overlayleft"></div>
        <div class="overlayright"></div>
    </div>
</template>

<script>

export default {
    name: 'MainScreen',

    methods: {
        openLink(url, newtab) {
            if (newtab) {
                window.open(url,'_blank')
            } else {
                window.open(url, '_self')
            }
        }
    }
}
</script>

<style scoped>
.text-block {
    color: white; /* Choose a color that contrasts well with the background */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Horizontal, vertical shadow position, blur, and color */
    font-size: 2em; /* Adjust size as needed */
    /* Other font styles like font-weight, line-height, etc. */
}
.container {
    background: url('../assets/2.png'), url('../assets/1.png'), #000001;
    background-size: cover, contain;
    background-repeat: no-repeat;
    background-position: bottom;

    position: relative;
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-top: 1.875rem;
}

.nav svg {
    margin-right: 1.5rem;
}

.mainscreen {
    height: 45.5rem;

    position: relative;
    z-index: 1;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.mainscreen h1 {
    max-width: 38rem;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 1.25rem;
    margin-top: 1.875rem
}

.overlaybottom {
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #0A0A0A 73.44%);
    bottom: 0;
    left: 0;
    width: 100%;
    height: 9rem;
}

.overlayleft {
    position: absolute;
    background: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, #0A0A0A 73.44%);
    left: 0;
    top: 0;
    width: 5%;
    height: 100%;
}

.overlayright {
    position: absolute;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #0A0A0A 73.44%);
    right: 0;
    top: 0;
    width: 5%;
    height: 100%;
}


@media screen and (max-width: 1100px) {
    .mainscreen {
        height: 35.5rem;
    }
}

@media screen and (max-width: 1000px) {
    .container {
        background-size: cover, 1000px;
        background-position: bottom center;
    }
}

</style>